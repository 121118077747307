<template>
  <div>
    <dao-hang></dao-hang>
    <zhong></zhong>
    <router-view :key="$route.path + $route.query.t"></router-view>
    <bottom></bottom>
  </div>
</template>

<script>
import Bottom from "../components/common/Bottom.vue";
import DaoHang from "../components/zoujin/DaoHang";
import Zhong from "../components/zoujin/Zhong";
export default {
  name: "Zou",
  components: {
    DaoHang,
    Bottom,
    Zhong,
  },
};
</script>

<style scoped></style>
