<template>
  <div>
    <div class="dao2" >
      <!--<router-link :to="{name:'a'}" v-for="item in data" :key="item.id"><span> {{item.title}}</span></router-link>-->
       <div> <router-link  :to="{name:'a'}"> <span :class="{active:active=='a'}" @click="active='a'">大伟嘉简介</span></router-link></div>
       <div><router-link  :to="{name:'b'}"><span :class="{active:active=='b'}" @click="active='b'">董事长介绍</span></router-link></div>
       <div><router-link  :to="{name:'g'}"><span :class="{active:active=='g'}" @click="active='g'">领导关怀</span></router-link></div>
       <div><router-link  :to="{name:'c'}"><span :class="{active:active=='c'}" @click="active='c'">企业荣誉</span></router-link></div>
       <div><router-link  :to="{name:'e'}"><span :class="{active:active=='e'}" @click="active='e'">产品中心</span></router-link></div>
       <div><router-link  :to="{name:'f'}"><span :class="{active:active=='f'}" @click="active='f'">发展历程</span></router-link></div>
       <div><router-link  :to="{name:'meng'}"><span :class="{active:active=='meng'}" @click="active='meng'">企业文化</span></router-link></div>
    </div>
    <div class="xian"></div>
  </div>
</template>

<script>
  import {getAboutList} from "../../api/index"
  export default {
    name: "Zhong",
    data(){
      return{
        data:[],
        active:''//初始化
      }
    },
    methods:{
      haha() {
        console.log("卧槽")
        this.$router.go(1);
      }
    },
    mounted() {
      console.log(this.$route)
      getAboutList().then(res=>{
        // console.log(res.data);
        let data = res.data;
        this.data = data;
      })

    },

    watch:{
      $route:{
        deep:true,
        immediate:true,
        handler(newV,olV) {
          console.log(this.active)
          this.active = newV.name;
          console.log(newV,"hello大家好")
          console.log(this.active,"??????")
        }
      }
    }
  }
</script>

<style scoped>
  .dao2{
    height:3.5937vw;
    margin-left: 25vw;
    line-height: 3.5937vw;
    display: flex;
  }
  .dao2 div{
    width: 6.5313vw;
    height: 1.0938vw;
    font-size: 1.1458vw;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #949090;
    padding-right: 2.0833vw;
    padding-bottom: 1.0417vw;
  }
  .dao2 div span{
   padding-bottom: 1.0417vw;
  }
  .xian{
    width: 93.0200vw;
    height: 0.0529vw;
    opacity: 0.6;
    background-color: #A2A2A2;
    margin-left: 3.4896vw;

  }
  .active{
    color: red;
    border-bottom: 0.15vw solid red;
  }
</style>