<template>
<div>
  <!-- <dao></dao> -->
  <div class="img">
    <img src="../../assets/金隅嘉华大厦.png" alt="">
  </div>
  <div class="zou"  >
    <div class="han">走进大伟嘉</div>
    <div class="ying">Enter VICA</div>
  </div>
</div>
</template>

<script>
  // import Dao from "../common/Dao"
  import {getAboutList} from "../../api/index"

  export default {
    name: "ChanYe",
    components:{
      // Dao
    },
    data(){
      return{
        data:[]
      }
    },
    mounted() {
      getAboutList().then(res=>{
        // console.log(res.data);
        let data = res.data;
        this.data = data;
      })

    }
  };
</script>

<style lang="less" scoped>
  a:hover {
    color: #d3001c;

  }
  .img img{
    width: 100%;
    height: 48.6891vh;
  }
  .zou{
    width: 11.1979vw;
    height: 13.9551vh;
    opacity: 0.8;
    background-color: #d7000f;
    position: absolute;
    margin-left: 5.5625vw;
    margin-top: -9.2vh;
  }
  .han{
    width: 10.7083vw;
    height: 1.4063vw;
    font-size: 1.5625vw;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: left;
    color: #ffffff;
    margin-top:0.771vw ;
    margin-left: 1vw;
  }
  .ying{
    width: 10.8125vw;
    height: 1.1979vw;
    font-size: 1.46vw;
    font-family: PingFang SC, PingFang SC-Light;
    font-weight: 300;
    text-align: left;
    color: #ffffff;
    margin-left: 1vw;
    margin-top: 0.7vw;
  }
</style>
